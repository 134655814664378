import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
export default {
  components: {
    Splide,
    SplideSlide
  },
  props: {
    options: {
      type: Object
    },
    rootAdditionalClass: {
      type: String,
      default: ''
    },
    additionalClass: {
      type: String,
      default: ''
    },
    slides: {
      type: Array
    },
    splideTrackHeightMobile: {
      type: Number,
      default: 0
    },
    splideTrackHeightSmall: {
      type: Number,
      default: 0
    },
    splideTrackHeight: {
      type: Number,
      default: 0
    },
    customTrackHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customStyles() {
      const normal = this.splideTrackHeight === 0 ? 'auto' : this.splideTrackHeight + 'px'
      const mobile = this.splideTrackHeightMobile === 0 ? 'auto' : this.splideTrackHeightMobile + 'px'
      const small = this.splideTrackHeightSmall === 0 ? 'auto' : this.splideTrackHeightSmall + 'px'
      return {
        '--splide-track-height-mobile': mobile,
        '--splide-track-height': normal,
        '--splide-track-height-small': small
      }
    },
    scssProps() {
      if (this.customTrackHeight === false) {
        return {
          '--splide-track-height-mobile': 'auto',
          '--splide-track-height': 'auto',
          '--splide-track-height-small': 'auto'
        }
      }
      return this.customStyles
    }
  }
}
